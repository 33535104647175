import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-featured-videos-two',
  templateUrl: './featured-videos-two.component.html',
  styleUrls: ['./featured-videos-two.component.scss']
})
export class FeaturedVideosTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
