<section id="videos" class="featured-videos-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>My Popular Videos</h2>
            <a routerLink="/" class="link-btn">See All</a>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-12">
                <div class="row">
                    <div class="col-lg-12 col-md-6 col-sm-6">
                        <div class="single-featured-videos-box">
                            <div class="videos-image">
                                <img src="assets/img/featured-videos/img1.jpg" alt="image">

                                <span class="duration">03.05</span>
                                <span class="icon"><i class="flaticon-video-camera"></i></span>
                                <a href="https://www.youtube.com/watch?v=74xydaU_GfY" class="link-btn popup-youtube"></a>
                            </div>

                            <div class="videos-content">
                                <h3><a href="https://www.youtube.com/watch?v=74xydaU_GfY" class="popup-youtube">Army vet appalled at hoarding does something incredible</a></h3>
                                <ul class="videos-meta">
                                    <li>4.3M views</li>
                                    <li>1 day ago</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6">
                        <div class="single-featured-videos-box">
                            <div class="videos-image">
                                <img src="assets/img/featured-videos/img2.jpg" alt="image">

                                <span class="duration">04.09</span>
                                <span class="icon"><i class="flaticon-video-camera"></i></span>
                                <a href="https://www.youtube.com/watch?v=74xydaU_GfY" class="link-btn popup-youtube"></a>
                            </div>

                            <div class="videos-content">
                                <h3><a href="https://www.youtube.com/watch?v=74xydaU_GfY" class="popup-youtube">How Micro-Influencers Can Transform Your Business</a></h3>
                                <ul class="videos-meta">
                                    <li>5.0M views</li>
                                    <li>2 day ago</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-featured-videos-box">
                    <div class="videos-image">
                        <img src="assets/img/featured-videos/img5.jpg" alt="image">

                        <span class="duration">10.14</span>
                        <span class="icon"><i class="flaticon-video-camera"></i></span>
                        <a href="https://www.youtube.com/watch?v=74xydaU_GfY" class="link-btn popup-youtube"></a>
                    </div>

                    <div class="videos-content">
                        <h3><a href="https://www.youtube.com/watch?v=74xydaU_GfY" class="popup-youtube">Everything You Need to Know about FTC Influencer Marketing Guidelines</a></h3>
                        <ul class="videos-meta">
                            <li>1.1M views</li>
                            <li>5 day ago</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-12">
                <div class="row">
                    <div class="col-lg-12 col-md-6 col-sm-6">
                        <div class="single-featured-videos-box">
                            <div class="videos-image">
                                <img src="assets/img/featured-videos/img3.jpg" alt="image">

                                <span class="duration">03.00</span>
                                <span class="icon"><i class="flaticon-video-camera"></i></span>
                                <a href="https://www.youtube.com/watch?v=74xydaU_GfY" class="link-btn popup-youtube"></a>
                            </div>

                            <div class="videos-content">
                                <h3><a href="https://www.youtube.com/watch?v=74xydaU_GfY" class="popup-youtube">How to Successfully Negotiate with Influencers</a></h3>
                                <ul class="videos-meta">
                                    <li>4.0M views</li>
                                    <li>3 day ago</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-6 col-sm-6">
                        <div class="single-featured-videos-box">
                            <div class="videos-image">
                                <img src="assets/img/featured-videos/img4.jpg" alt="image">

                                <span class="duration">05.00</span>
                                <span class="icon"><i class="flaticon-video-camera"></i></span>
                                <a href="https://www.youtube.com/watch?v=74xydaU_GfY" class="link-btn popup-youtube"></a>
                            </div>

                            <div class="videos-content">
                                <h3><a href="https://www.youtube.com/watch?v=74xydaU_GfY" class="popup-youtube">How to Find the Perfect Influencers for Your Niche</a></h3>
                                <ul class="videos-meta">
                                    <li>2M views</li>
                                    <li>4 day ago</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>