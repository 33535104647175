import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-featured-videos',
  templateUrl: './featured-videos.component.html',
  styleUrls: ['./featured-videos.component.scss']
})
export class FeaturedVideosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
