<section  id="stories" class="top-stories-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Top vidéos</h2>
            <a routerLink="/" class="link-btn">See All</a>
        </div>

        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="single-top-stories-box">
                    <img src="assets/videos/covervideo1.png" alt="image">
                    <a href="assets/videos/astu.mp4" class="link-btn popup-facebook"></a>
                    <div class="content extra-pd">
                        <div class="d-flex justify-content-between align-items-center">
                            <span class="duration">00.30</span>
                            <span class="icon"><i class="flaticon-video-camera"></i></span>
                        </div>
                        <h3><a href="#">Pensez tennis</a></h3>
                        <ul class="meta">
                            <!--<li>4.3M views</li>-->
                            <li>Il y a 1j</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="row">
                    <div class="col-lg-12 col-md-6">
                        <div class="single-top-stories-box">
                            <img src="assets/videos/covervideo2.png" alt="image">
                            <a href="assets/videos/video2.mp4" class="link-btn popup-youtube"></a>
                            <div class="content">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span class="duration">00.40</span>
                                    <span class="icon"><i class="flaticon-video-camera"></i></span>
                                </div>
                                <h3><a href="#">Le revers de Bamba</a></h3>
                                <ul class="meta">
                                    <!--<li>4.3M views</li>-->
                                    <li>Il y a 3j</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-6">
                        <div class="single-top-stories-box">
                            <img src="assets/videos/covervideo4.png" alt="image">
                            <a href="assets/videos/video4.mp4" class="link-btn popup-youtube"></a>
                            <div class="content">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span class="duration">00.15</span>
                                    <span class="icon"><i class="flaticon-video-camera"></i></span>
                                </div>
                                <h3><a href="#">Séance avec les débutants</a></h3>
                                <ul class="meta">
                                    <!--<li>4.3M views</li>-->
                                    <li>Il y a 2j</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
