<nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
        <a class="navbar-brand" routerLink="/"><img src="assets/img/logoastutennis.png" alt="image"></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <div class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item"><a class="nav-link" href="#home">Accueil</a></li>
                <li class="nav-item"><a class="nav-link" href="#blog">Le club</a></li>
                <li class="nav-item"><a class="nav-link" href="#about">À propos</a></li>
                <li class="nav-item"><a class="nav-link" href="#channels">Coach</a></li>
                <li class="nav-item"><a class="nav-link" href="#stories">Vidéos</a></li>
                <!--<li class="nav-item"><a class="nav-link" href="#playlists">Playlists</a></li>-->
                <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
            </ul>
            <!--<div class="others-option">
                <div class="option-item">
                    <div class="search-btn-box">
                        <i class="search-btn flaticon-search"></i>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</nav>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='flaticon-search'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
