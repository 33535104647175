<section class="partners-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nos partenaires</h2>
            <!--<a routerLink="/" class="link-btn">See All</a>-->
        </div>

        <div class="row">
            <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                <div class="single-partners-box">
                    <a href="https://www.expertsassocies.org" target="_blank">
                        <img src="assets/img/partner/partner1.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                <div class="single-partners-box">
                    <a href="https://www.sosmedecinsenegal.com" target="_blank">
                        <img src="assets/img/partner/partner2.png" alt="image">
                    </a>
                </div>
            </div>

            <!--<div class="col-lg-3 col-sm-6 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img3.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img4.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img5.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img6.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img7.png" alt="image">
                    </a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-4 col-6">
                <div class="single-partners-box">
                    <a href="#">
                        <img src="assets/img/partner/img8.png" alt="image">
                    </a>
                </div>
            </div>-->
        </div>
    </div>
</section>
