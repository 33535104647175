<section id="about" class="about-area bg-f5eded ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="about-content">
                    <h2>À propos du club</h2>
                    <h5>ASTU TENNIS, votre club de sport à Dakar</h5>
                    <p>Nous mettons à votre disposition 4 courts de tennis, 1 terrain de foot à huit et 3 salles de Squash.</p>
                    <img src="assets/img/astuhome.png" alt="image">
                    <p>Prenez en main votre santé en vous inscrivant à notre club de sport</p>
                    <div class="btn-box">
                        <a href="#" class="default-btn"  data-bs-toggle="modal" data-bs-target="#contactModal">Contactez-nous</a>
                        <a href="mailto:hello@astu.club" class="email">hello@astu.club</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="about-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <!--<li>
                                    Nom:
                                    <span>ASTU Tennis</span>
                                </li>-->
                                <li>
                                    Email:
                                    <span><a href="mailto:hello@astu.club" class="email">hello@astu.club</a></span>
                                </li>
                                <li>
                                    Adresse:
                                    <span>UCAD rond point IFAN, Dakar, Sénégal</span>
                                </li>
                                <li>
                                    Téléphone:
                                    <span><a href="tel:+2278549857">+221 77 934 84 98</a></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
