<section id="playlists" class="videos-playlists-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>My Video Playlists</h2>
            <a routerLink="/" class="link-btn">See All</a>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-playlists-videos-box">
                    <div class="videos-image">
                        <img src="assets/img/featured-videos/img1.jpg" alt="image">

                        <span class="duration">15.05</span>
                        <span class="icon"><i class="flaticon-video-camera"></i></span>
                        <a href="#" class="link-btn"></a>
                        <div class="playlists-icon"><i class="flaticon-playlist"></i><span>12</span></div>
                    </div>

                    <div class="videos-content">
                        <h3>TikTok</h3>
                        <a href="#" class="link-btn">View Full Playlist</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-playlists-videos-box">
                    <div class="videos-image">
                        <img src="assets/img/featured-videos/img2.jpg" alt="image">

                        <span class="duration">20.00</span>
                        <span class="icon"><i class="flaticon-video-camera"></i></span>
                        <a href="#" class="link-btn"></a>
                        <div class="playlists-icon"><i class="flaticon-playlist"></i><span>10</span></div>
                    </div>

                    <div class="videos-content">
                        <h3>Travelling</h3>
                        <a href="#" class="link-btn">View Full Playlist</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-playlists-videos-box">
                    <div class="videos-image">
                        <img src="assets/img/featured-videos/img3.jpg" alt="image">

                        <span class="duration">11.11</span>
                        <span class="icon"><i class="flaticon-video-camera"></i></span>
                        <a href="#" class="link-btn"></a>
                        <div class="playlists-icon"><i class="flaticon-playlist"></i><span>15</span></div>
                    </div>

                    <div class="videos-content">
                        <h3>Mobile Review</h3>
                        <a href="#" class="link-btn">View Full Playlist</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-playlists-videos-box">
                    <div class="videos-image">
                        <img src="assets/img/featured-videos/img4.jpg" alt="image">

                        <span class="duration">21.01</span>
                        <span class="icon"><i class="flaticon-video-camera"></i></span>
                        <a href="#" class="link-btn"></a>
                        <div class="playlists-icon"><i class="flaticon-playlist"></i><span>25</span></div>
                    </div>

                    <div class="videos-content">
                        <h3>Laptop Review</h3>
                        <a href="#" class="link-btn">View Full Playlist</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>