<section id="blog" class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Services du club</h2>
            <!--<a routerLink="/" class="link-btn">See All</a>-->
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/"><img src="assets/img/blog/tennis.png" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <a routerLink="/" class="category">Tennis</a>
                        <h3><a routerLink="/">le club de tennis dispose de 4 courts de tennis sur dur</a></h3>
                        <ul class="post-meta">
                            <li>7j/7</li>
                            <li>07h à 22h15</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/"><img src="assets/img/blog/foot.png" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <a routerLink="/" class="category">Football</a>
                        <h3><a routerLink="/">le terrain de foot peut abriter une équipe de 7X7 plus 1 gardien </a></h3>
                        <ul class="post-meta">
                            <li>7j/7</li>
                            <li>07h à 22h15</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/"><img src="assets/img/blog/squash.png" alt="image"></a>
                    </div>

                    <div class="post-content">
                        <a routerLink="/" class="category">Squash</a>
                        <h3><a routerLink="/">Le club met à votre disposition 3 salles de Squash</a></h3>
                        <ul class="post-meta">
                            <li>7j/7</li>
                            <li>07h à 22h15</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
