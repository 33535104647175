<div id="home" class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <h1><span>Bienvenue à</span> Astu Tennis</h1>
                    <p>Votre club de tennis à Dakar</p>
                    <div class="btn-box">
                        <a href="#" data-bs-toggle="modal" data-bs-target="#contactModal" class="default-btn">Inscription</a>
                        <ul>
                            <li><a href="https://www.facebook.com/MODOU.FALL779348498" class="facebook" target="_blank"><i class="flaticon-facebook"></i></a></li>
                            <!--<li><a href="#" class="twitter" target="_blank"><i class="flaticon-twitter"></i></a></li>-->
                            <li><a href="https://www.instagram.com/astu.tennis.dakar/" class="instagram" target="_blank"><i class="flaticon-instagram"></i></a></li>
                            <!--<li><a href="#" class="pinterest" target="_blank"><i class="flaticon-pinterest"></i></a></li>-->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/shape2.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape5"><img src="assets/img/shape5.png" alt="image"></div>
    <div class="shape6"><img src="assets/img/shape6.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape8.png" alt="image"></div>
</div>
