<section id="channels" class="featured-channels-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nos coachs</h2>
            <a routerLink="/" class="link-btn">Voir tout</a>
        </div>

        <div class="row">

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-channels-box">
                    <a href="#" class="image">
                        <img src="assets/img/coach/coach2.jpeg" alt="image" class="img-coach">
                    </a>

                    <div class="content">
                        <h3><a href="#">Papa Samba</a></h3>
                        <span>77 479 81 45</span>
                        <a href="tel:774798145" class="link-btn">Contacter</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-channels-box">
                    <a href="#" class="image">
                        <img src="assets/img/coach/coach1.jpeg" alt="image" class="img-coach">
                    </a>

                    <div class="content">
                        <h3><a href="#">Mamadou Fall</a></h3>
                        <span>77 934 84 98</span>
                        <a href="tel:779348498" class="link-btn">Contacter</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-featured-channels-box">
                    <a href="#" class="image">
                        <img src="assets/img/coach/coach3.jpeg" alt="image" class="img-coach">
                    </a>

                    <div class="content">
                        <h3><a href="#">Mouhamed Loko </a></h3>
                        <span>77 605 17 17</span>
                        <a href="tel: 776051717" class="link-btn">Contacter</a>
                    </div>
                </div>
            </div>

            <!--<div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-featured-channels-box">
                    <a href="#" class="image">
                        <img src="assets/img/channels/img4.jpg" alt="image" class="img-coach">
                    </a>

                    <div class="content">
                        <h3><a href="#">Oumar Dia</a></h3>
                        <span>77 890 09 93</span>
                        <a href="#" class="link-btn">Contacter</a>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</section>
