import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { HomeComponent } from './components/pages/home/home.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { JoinComponent } from './components/layouts/join/join.component';
import { BlogComponent } from './components/layouts/blog/blog.component';
import { FeaturedChannelsComponent } from './components/layouts/featured-channels/featured-channels.component';
import { TopStoriesComponent } from './components/layouts/top-stories/top-stories.component';
import { PartnersComponent } from './components/layouts/partners/partners.component';
import { VideosPlaylistsComponent } from './components/layouts/videos-playlists/videos-playlists.component';
import { FeaturedVideosComponent } from './components/layouts/featured-videos/featured-videos.component';
import { AboutComponent } from './components/layouts/about/about.component';
import { FeaturedVideosTwoComponent } from './components/layouts/featured-videos-two/featured-videos-two.component';
import { MainBannerComponent } from './components/layouts/main-banner/main-banner.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    HomeComponent,
    BlogDetailsComponent,
    JoinComponent,
    BlogComponent,
    FeaturedChannelsComponent,
    TopStoriesComponent,
    PartnersComponent,
    VideosPlaylistsComponent,
    FeaturedVideosComponent,
    AboutComponent,
    FeaturedVideosTwoComponent,
    MainBannerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
