<footer class="footer-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <p>© Astu tennis powered by <a href="https://www.andando.sn/" target="_blank">Andando</a></p>
            </div>
            <div class="col-lg-6 col-md-6">
                <ul>
                    <li><a href="https://www.facebook.com/MODOU.FALL779348498" target="_blank"><i class="flaticon-facebook"></i></a></li>
                    <!--<li><a href="https://www.facebook.com/MODOU.FALL779348498" target="_blank"><i class="flaticon-twitter"></i></a></li>-->
                    <!--<li><a href="#" target="_blank"><i class="flaticon-pinterest"></i></a></li>-->
                    <li><a href="https://www.instagram.com/astu.tennis.dakar/" target="_blank"><i class="flaticon-instagram"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>

<!-- Modal -->
<div class="modal fade contactModal" id="contactModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <input type="text" name="name" id="name" class="form-control" placeholder="Entrer votre nom">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <input type="email" name="email" id="email" class="form-control" placeholder="Entrer votre email">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Entrer votre message"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="default-btn">Envoyer Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
