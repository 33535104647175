<section id="contact" class="join-area">
    <div class="container">
        <div class="join-content">
            <h2>Rejoignez-nous</h2>
            <p>Les inscriptions sont ouvertes tout au long de l'année</p>
            <div class="btn-box">
                <a href="#" class="default-btn" data-bs-toggle="modal" data-bs-target="#contactModal">Contactez-nous</a>
                <a href="mailto:hello@astu.club" class="email">hello@astu.club</a>
            </div>
        </div>
    </div>
</section>
